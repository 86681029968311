<template>
  <div>
    <div class="menu-container">
      <v-row class="d-flex justify-center align-content-center">
        <v-col cols="10" sm="7" class="mx-0 d-flex row">
          <div class="menu-header">
            <div>
              <h2 class="menu-sub">VAPING IS ON THE</h2>
              <h1 class="menu-title p-0">MOOV™</h1>
            </div>
            <p>
              Introducing the New Logic Vape, with an updated intuitive design,
              MOOV™ technology that lets you control your device with simple
              motions, and Haptic feedback that keeps you in-the-know.
            </p>
          </div>

          <v-row class="menu-item-container">
            <v-col cols="6" md="4" class="menu-item px-0 py-0 py-md-2">
              <router-link to="/about">
                <v-img
                  class="menu-button mx-auto"
                  src="../assets/images/icon-about.png"
                  alt="About Logic"
                  position="center center"
                  contain
                />
                <h6 class="mt-2 pb-2 pt-2">ABOUT LOGIC VAPE</h6>
              </router-link>
            </v-col>
            <v-col cols="6" md="4" class="menu-item px-0 py-0 py-md-2">
              <router-link to="/how-to-use">
                <v-img
                  class="menu-button mx-auto"
                  src="../assets/images/icon-how_to_use.png"
                  alt="What Is Vaping"
                  position="center center"
                  contain
                />
                <h6 class="mt-2 mb-0 pb-2 pt-2">HOW TO USE</h6>
              </router-link>
            </v-col>
            <v-col cols="6" md="4" class="menu-item px-0 py-0 py-md-2">
              <router-link to="/video">
                <v-img
                  class="menu-button mx-auto"
                  src="../assets/images/icon-video.png"
                  alt="News"
                  position="center center"
                  contain
                />
                <h6 class="mt-2 pb-2 pt-2">EDUCATIONAL VIDEOS</h6>
              </router-link>
            </v-col>
            <v-col cols="6" md="4" class="menu-item px-0 py-0 py-md-2">
              <router-link to="/product-range">
                <v-img
                  class="menu-button mx-auto"
                  src="../assets/images/icon-product_range.png"
                  alt="Product Range"
                  position="center center"
                  contain
                />
                <h6 class="mt-2 pb-2 pt-2">PRODUCT RANGE</h6>
              </router-link>
            </v-col>

            <v-col cols="6" md="4" class="menu-item px-0 py-0 py-md-2">
              <router-link to="/faq">
                <v-img
                  class="menu-button mx-auto"
                  src="../assets/images/icon-faq.png"
                  alt="Faqs"
                  position="center center"
                  contain
                />
                <h6 class="mt-2 pb-2 pt-2">FAQ</h6>
              </router-link>
            </v-col>
            <v-col cols="6" md="4" class="menu-item px-0 py-0 py-md-2">
              <a href="/promotions">
                <v-img
                  class="menu-button mx-auto"
                  src="../assets/images/icon-promotions.png"
                  alt="Promotions"
                  position="center center"
                  contain
                />
                <h6 class="mt-2 pb-2 pt-2">PROMOTIONS</h6>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
};
</script>

<style lang="scss">
@import "../assets/scss/main.scss";

.menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100vh;
  z-index: 10;
  position: relative;
  max-width: 80vw;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
  }
}

.menu-item-container {
  width: min(40rem, 60vw);
  margin: 0 !important;
  z-index: 100 !important;
  position: relative;
  padding: min(3vw, 3vw) min(4rem, 6vw);
  background-image: url("../assets/images/background_inner.png");
  background-size: contain;
  background-position: center center;
}

.menu-title {
  font-size: max(5.25vw, 6vh);
  line-height: 1em;
  font-weight: normal;
  font-family: $primary-font-black, "Montserrat";
  color: white !important;
  -webkit-text-fill-color: white !important;
  font-weight: 900;
}

.menu-sub {
  font-weight: normal;
  font-size: max(2.25vw, 2.5vh);
  color: white;
  position: relative;
  bottom: min(-1vw, -1vh);
  font-family: $primary-font-book, "Montserrat";
}

.menu-header {
  text-align: center;
  font-family: $primary-font-book, "Montserrat";
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 30vh;
  > * {
    margin: 0 !important;
  }

  p {
    font-size: max(0.9vw, 1.1vh);
    line-height: 22px;
    color: white;

    @media only screen and (max-width: 768px) {
      font-size: 11px;
      line-height: 1;
    }
  }
}

.menu-button-row {
  padding: 20px;
  width: 100%;
}

.menu-button {
  width: 80%;
}

.social-container-row {
  display: flex;
  width: 100%;
  justify-content: center;
}

.social-image {
  margin: 10px 50px 0 10px;
  width: 35px;
}

.fade-in {
  animation: fadeIn 1s forwards;
}

.inactive {
  display: none;
}

.menu-item {
  text-align: center;
  font-family: $primary-font-black, "Montserrat" !important;

  h6 {
    font-size: 1vw;
    color: #fff;

    @media only screen and (max-width: 768px) {
      font-size: 10px;
    }

    @media (min-width: 768px) {
      line-height: 1.1vw !important;
    }
  }

  a {
    text-decoration: none;
  }

  .v-image {
    width: 5vw;

    @media only screen and (max-width: 768px) {
      width: 50px;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media only screen and (max-height: 790px) {
  .social-image {
    width: 25px;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .menu-item-container {
    width: 1000px;
  }
}

@media (max-width: 576px) {
  .main-menu-launch-video-container > .modal-container > .close-modal {
    width: 8%;
  }

  .menu-item-container {
    margin: 0 !important;
    padding: 8vw;
    background-image: url("../assets/images/background_inner_mobile_straight.png");
    background-size: contain;
    background-position: center center;
    position: relative;
    p {
      font-size: 10px;
      line-height: 10px;
    }
  }

  @media only screen and (max-height: 450px) {
    .modal-bg {
      height: 80vh;
    }

    .menu-button-row {
      width: 50%;
    }
  }
}

// @media (max-width: 992px) and (max-height: 500px) {
//   .menu-item-container {
//     margin: 0 !important;
//     padding: 0vw;
//     background-image: url("../assets/images/background_inner.png") !important;
//     background-size: contain;
//     background-position: center center;
//     position: relative;
//     p {
//       font-size: 10px;
//       line-height: 10px;
//     }
//   }
// }

.v-dialog--persistent {
  box-shadow: unset !important;
}

.video-container {
  background: none !important;
  height: 40vh !important;
  min-height: 290px;
  border-radius: 0 !important;
  border: none !important;
  margin-top: 50px;
  z-index: 2;
  flex-direction: column;

  video {
    border: 2px solid #fff;
  }
}

.intro-text {
  text-transform: uppercase;
  font-size: 1.2em;
}

.close-video-button {
  z-index: 10;
  position: absolute;

  img {
    width: 80%;
  }
}

.v-card {
  color: #1e4b8b !important;

  &__title {
    text-transform: uppercase;
    line-height: 1.2em !important;
  }

  &__text {
    color: #1e4b8b !important;
    line-height: 1.2em !important;
  }
}

// @media only screen and (max-width: 670px) {
//   .intro-text {
//     text-transform: uppercase;
//     font-size: 0.8em;
//   }

//   .video-container {
//     height: 200px !important;
//   }

//   .close-video-button img {
//     width: 50%;
//   }

//   .menu-title {
//     font-size: 1rem;
//   }

//   .menu-sub {
//     font-size: 0.7rem !important;
//     line-height: 1rem;
//     margin: 0 0 20px;
//   }
// }

.dialog-btn-container {
  flex-direction: column;
}

.angled-btn {
  cursor: pointer;
  clip-path: polygon(91% 0, 100% 50%, 91% 100%, 9% 100%, 0 50%, 9% 0);
  margin: 0 auto;
  display: block;
  font-weight: bold;
  font-size: 14px;
  background-color: rgba(30, 75, 139, 1);
  border: none;
  transition: all 0.2s ease-in-out;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  padding: 15px 50px;
  font-weight: 600;
}
</style>
