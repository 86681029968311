<template>
  <v-app id="app">
    <NewHeader />
    <v-main>
      <NewAcrossPageHexes />
      <router-view />
      <Menu v-if="$route.name === 'Menu'" />
    </v-main>
    <NewFooter />
  </v-app>
</template>

<script>
import NewFooter from "./components/NewFooter.vue";
import NewAcrossPageHexes from "./components/NewAcrossPageHexes.vue";
import Menu from "./views/Menu.vue";
import NewHeader from "./components/NewHeader.vue";

export default {
  components: {
    NewFooter,
    NewAcrossPageHexes,
    Menu,
    NewHeader,
  },
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";

.v-main {
  width: 100%;
  height: 100vh;
  background-color: #722894;
  background-image: url("./assets/images/background.png");
  background-repeat: no-repeat;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-size: cover;
  background-position: center;
}

.theme--light.v-application {
  background: none !important;
}

.grecaptcha-badge {
  display: none !important;
}
</style>
