import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c('div',{staticClass:"inner-container"},[_c(VRow,{staticClass:"age-gate ma-0 justify-content-center"},[_c(VCol,{staticClass:"age-gate-header p-0",attrs:{"cols":"12"}},[_c('h1',[_vm._v(" TO PROCEED PLEASE CONFIRM YOU ARE 18+ YEAR OLD AND A MEMBER OF THE IRISH TOBACCO/NICOTINE TRADE ")])]),_c(VCol,[_c(VForm,{ref:"form",staticClass:"age-gate-form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"all-boxes p-0 px-lg-8"},[_c('div',{staticClass:"form-box-containers over-eighteen-box align-top"},[_c(VCheckbox,{attrs:{"rules":[(v) => !!v || 'You must agree to continue!'],"label":"I AM 18 YEARS OR OLDER","color":"#ffffff","value":"over18","hide-details":"","on-icon":"mdi-circle","off-icon":"mdi-circle","required":""},model:{value:(_vm.checkbox1),callback:function ($$v) {_vm.checkbox1=$$v},expression:"checkbox1"}})],1),_c('div',{staticClass:"form-box-containers tobacco-trade-box align-top"},[_c(VCheckbox,{attrs:{"rules":[(v) => !!v || 'You must agree to continue!'],"label":"I AM A MEMBER OF THE TOBACCO/NICOTINE TRADE","color":"#ffffff","value":"tobaccoMember","hide-details":"","on-icon":"mdi-circle","off-icon":"mdi-circle","required":""},model:{value:(_vm.checkbox2),callback:function ($$v) {_vm.checkbox2=$$v},expression:"checkbox2"}})],1)]),_c('div',{staticClass:"continue-button-border"},[_c(VBtn,{staticClass:"continue-btn rounded-pill",attrs:{"disabled":!_vm.valid},on:{"click":_vm.validate}},[_vm._v(" CONTINUE ")])],1)])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }