<template>
  <div>
    <v-col class="slider-container">
      <v-carousel hide-delimiters>
        <div class="close-button">
          <router-link to="/">
            <v-img class="close-modal" src="../assets/images/close-box.png" alt="close" />
          </router-link>
        </div>
        <template v-slot:prev="{ on, attrs }">
          <v-img v-bind="attrs" v-on="on" src="../assets/images/left-arrow.png" width="20" />
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-img v-bind="attrs" v-on="on" src="../assets/images/right-arrow.png" width="20" />
        </template>
        <div v-if="$vuetify.breakpoint.xs">
          <transition-group name="slide">
            <v-carousel-item v-for="(slide, i) in mobileSlides" :key="slide.src" :src="slide.src"
              class="mobile-slides"></v-carousel-item>
          </transition-group>
        </div>
        <div v-else>
          <transition-group name="slide">
            <v-carousel-item v-for="(slide, i) in slides" :key="slide.src" :src="slide.src"></v-carousel-item>
          </transition-group>
        </div>
      </v-carousel>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "News",
  data() {
    return {
      slides: [
        {
          src: require("../assets/images/tobacco-e-presenter-slide1.jpg"),
        },
        {
          src: require("../assets/images/tobacco-e-presenter-slide2.jpg"),
        },
        {
          src: require("../assets/images/tobacco-e-presenter-slide3.jpg"),
        },
      ],
      mobileSlides: [
        {
          src: require("../assets/images/tobacco-e-presenter-slide1.jpg"),
        },
        {
          src: require("../assets/images/tobacco-e-presenter-slide2.jpg"),
        },
        {
          src: require("../assets/images/tobacco-e-presenter-slide3.jpg"),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.v-window {
  $icon_switch_height: 4vw;
  z-index: 1;

  &.v-item-group {
    height: 70vh !important;
  }

  .v-image.v-responsive.v-carousel__item {
    height: 100% !important;
    max-height: 80vh !important;
  }

  .v-window-item {
    display: block;
    align-items: center;
    height: 50vh !important;
    position: absolute;
    transform: translate(-50%, -50%) !important;
    top: 50% !important;
    left: 50% !important;

    .v-image__image {
      background-size: contain !important;
    }

    @media (max-width: 900px) {
      height: 400px !important;
    }

    @media (max-width: 576px) {
      &.mobile-slides {
        .v-image__image {
          background-size: contain !important;
          height: 200px !important;
          top: 50%;
          transform: translateY(-50%) !important;
        }
      }

      left: 50% !important;
    }


  }

  &__next {
    background: none !important;
    transform: translate(-50%, -50%) !important;
    top: 50% !important;
    left: 90% !important;
    height: $icon_switch_height !important;
    margin: 0 !important;

    .v-image__image,
    .v-responsive__sizer,
    .v-responsive__content,
    .v-image.v-responsive {
      cursor: pointer;
      width: $icon_switch_height !important;
      height: $icon_switch_height !important;
    }
  }

  &__prev {
    background: none !important;
    transform: translate(-50%, -50%) !important;
    top: 50% !important;
    left: 10% !important;
    height: $icon_switch_height !important;
    margin: 0 !important;

    .v-image__image,
    .v-responsive__sizer,
    .v-responsive__content,
    .v-image.v-responsive {
      cursor: pointer;
      width: $icon_switch_height !important;
      height: $icon_switch_height !important;
    }
  }

  @media (max-width: 576px) {
    &__next {
      left: 95% !important;
    }

    &__prev {
      left: 5% !important;
    }
  }

  .slide {

    &-enter-active,
    &-leave-active {
      transition: transform 0.5s;
    }

    &-enter,
    &-leave-to {
      transform: translateX(100%);
      opacity: 0;
    }

    &-enter-to,
    &-leave {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .carousel {
    overflow: hidden;
    white-space: nowrap;
  }

  .carousel-item {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    transition: transform 0.5s;
  }

  .carousel-enter,
  .carousel-leave-to {
    transform: translateX(-100%);
  }

  .carousel-leave-active,
  .carousel-enter-active {
    transition: transform 0.5s;
  }

  .carousel-enter-active {
    animation: carouselSlideIn 0.5s;
  }

  .carousel-leave-active {
    animation: carouselSlideOut 0.5s;
  }

  @keyframes carouselSlideIn {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes carouselSlideOut {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(100%);
    }
  }
}

.slider-container {
  display: flex;
  height: 100vh;
  // padding: 10%;
  align-items: center;
  justify-content: center;

  .close-button {
    display: block;
    margin: 0 auto;
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 10vh;
    width: 25px;
    height: 25px;
  }

  @media (min-width: 380px) and (max-width: 395px) {
    .close-button {
      top: 20.5vh !important;
      display: block;
      margin: 0 auto;
      z-index: 1;
      position: absolute;
      transform: translate(-50%, -50%);
      right: 50%;
      width: 25px;
      height: 25px;
    }
  }

  .close-modal {
    margin-bottom: -30px;
    width: 3vw !important;
    z-index: 1;
    position: relative;
  }

  // @media (min-width: 576px) and (max-width: 992px) {
  //   .close-button {
  //     top: max(15vw, 19.5vh);
  //     left: 50%;
  //   }
  // }

  @media (max-width: 576px) {
    .close-button {
      top: 2vh !important;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .close-modal {
      width: 8vw !important;
    }
  }

  @media (max-width: 992px) and (max-height: 500px) {
    .close-button {
      top: 1vh !important;
    }
  }
}
</style>
