<template>
  <v-container>
    <div class="inner-container">
      <v-row class="age-gate ma-0 justify-content-center">
        <v-col class="age-gate-header p-0" cols="12">
          <h1>
            TO PROCEED PLEASE CONFIRM YOU ARE 18+ YEAR OLD AND A MEMBER OF THE
            IRISH TOBACCO/NICOTINE TRADE
          </h1>
        </v-col>
        <v-col>
          <v-form class="age-gate-form" ref="form" v-model="valid">
            <div class="all-boxes p-0 px-lg-8">
              <div class="form-box-containers over-eighteen-box align-top">
                <v-checkbox
                  v-model="checkbox1"
                  :rules="[(v) => !!v || 'You must agree to continue!']"
                  label="I AM 18 YEARS OR OLDER"
                  color="#ffffff"
                  value="over18"
                  hide-details
                  on-icon="mdi-circle"
                  off-icon="mdi-circle"
                  required
                ></v-checkbox>
              </div>
              <div class="form-box-containers tobacco-trade-box align-top">
                <v-checkbox
                  v-model="checkbox2"
                  :rules="[(v) => !!v || 'You must agree to continue!']"
                  label="I AM A MEMBER OF THE TOBACCO/NICOTINE TRADE"
                  color="#ffffff"
                  value="tobaccoMember"
                  hide-details
                  on-icon="mdi-circle"
                  off-icon="mdi-circle"
                  required
                ></v-checkbox>
              </div>
            </div>
            <div class="continue-button-border">
              <v-btn
                class="continue-btn rounded-pill"
                :disabled="!valid"
                @click="validate"
              >
                CONTINUE
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "AgeGate",

  data: () => ({
    valid: true,
    checkbox1: false,
    checkbox2: false,
  }),

  beforeCreate() {
    if (localStorage.ageCheck === "true" || localStorage.ageCheck === true) {
      this.$router.push({ name: "Menu" });
    }
  },

  methods: {
    validate() {
      this.$refs.form.validate();

      if (this.valid) {
        localStorage.ageCheck = true;
        this.$router.push({ name: "Menu" });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/main.scss";

.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1565px !important;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: 1000px !important;
  }
}

.v-main {
  @media (max-width: 768px) {
    background-image: url("../assets/images/background_mobile_yellow.png") !important;
  }
  .continue-button-border .continue-btn button {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    color: white;
    padding: 15px 40px;
    border-radius: 40px;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    letter-spacing: 2px;

    &:hover {
      background-color: #000;
      border: 1px solid #000;
      color: #fff;
    }

    &:disabled {
      background-color: #f5f5f5;
      border: 1px solid #f5f5f5;
      color: #c9bedc;
      cursor: not-allowed;
    }

    @media (min-width: 576px) and (max-width: 900px) {
      padding: 1vw 2vw !important;
      font-size: max(1.6vh, 2.6vw);
    }
  }
}

.age-gate {
  color: #ffffff;
  // position: absolute;
  // transform: translate(-50%, -50%);
  // top: 50%;
  // left: 50%;
  align-content: center;
  background: url("../assets/images/background_inner_yellow.png") no-repeat
    center center;
  background-size: contain;
  height: 65vh;

  .v-icon {
    font-size: max(1.2rem, 2.5vw) !important;
  }

  .v-label {
    left: 15px !important;
    font-size: 13px;
    font-weight: 600;
    font-family: $primary-font-bold;

    &.theme--light {
      color: #ffffff !important;
    }
  }

  .v-input--is-label-active label {
    color: #ffffff;
  }
}

.age-gate-header > h1 {
  text-align: center;
  color: white !important;
  -webkit-text-fill-color: white;
  font-size: max(1.3rem, 1vw);
  width: 85%;
  margin: 0 auto;
  font-weight: bold !important;
  font-family: $primary-font-bold;
}

.ts-cs-box {
  margin-top: -10px;
}

.error-message {
  height: 18px;
  color: rgb(179, 0, 0);
}

.fade-out {
  animation: fadeOut 1s forwards;
}

.all-boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 2rem;
}

.form-box-containers {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.continue-button-border {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  width: 200px;
}

.continue-btn {
  font-weight: bold;
  font-size: 1.4rem;
  border-radius: 30px;
  width: inherit;
  background-image: -webkit-linear-gradient(45deg, #bd7223, #912f10);
  color: #ffffff !important;
  padding: 10px 10px;
  height: unset !important;
  font-family: $primary-font-bold;
  letter-spacing: normal !important;

  .v-btn__content {
    font-family: $primary-font-bold;
    font-weight: 600;
    padding: 10px;
  }

  &:disabled {
    cursor: not-allowed !important;
    background-image: -webkit-linear-gradient(
      45deg,
      #8080806a,
      #a14416
    ) !important;
  }
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgb(194, 194, 194) !important;
  cursor: not-allowed !important;
  background-image: -webkit-linear-gradient(
    45deg,
    #8080806a,
    #a14416
  ) !important;
}

.custom-checkbox-container {
  position: relative;
  padding-left: 50px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  /* border-radius: 5px; */
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  height: 40px;
  width: 40px;
  background-color: #1b527a;
}

.checkmark-two {
  margin-top: -8px;
}

.custom-checkbox-container:hover input ~ .checkmark {
  background-color: #912f10;
}

.custom-checkbox-container input:checked ~ :after {
  display: block;
}

.custom-checkbox-container input:checked ~ .checkmark {
  background-color: none;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox-container .checkmark:after {
  left: 10px;
  top: 11px;
  width: 18px;
  height: 18px;
  border: solid #ffffff;
  border-width: 10px 10px 9px 10px;
  /* transform: rotate(45deg);*/
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.portrait-only {
  display: none;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* MEDIA QUERIES */
@media (max-width: 900px) {
  .inner-container {
    aspect-ratio: 800 / 1347;
    height: 100%;
    max-height: 472px;
  }

  .age-gate {
    position: relative;
    background-image: url("../assets/images/background_inner_mobile_yellow.png");
    background-size: contain;
    aspect-ratio: 800 / 1347;
    height: 100%;
    max-height: 532px;
  }

  .age-gate-form {
    margin-top: 20px;
    font-size: 0.8rem;
  }

  .form-box-containers {
    justify-content: flex-start !important;
  }

  @media (max-width: 380px) {
    .age-gate-form,
    .all-boxes {
      margin-bottom: 0 !important;
    }
  }
}

@media only screen and (max-width: 900px) {
  .age-gate-header > h1 {
    font-size: 2vh;
    line-height: 1.3;
    width: 100%;
    padding: 0;
  }

  .all-boxes {
    flex-direction: column;
    gap: 0;
    margin: 20px 0;
  }

  .age-gate-form {
    margin-top: 20px;
    font-size: 1rem;
  }

  .form-box-containers {
    margin: 20px auto 20px 10px;
  }

  .continue-button-border {
    width: 80% !important;
    margin: 10% !important;
    display: flex;
    justify-content: center;
  }

  .continue-btn {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 670px) {
  .age-gate-form {
    margin-top: 20px;
    font-size: 0.8rem;
  }

  .form-box-containers {
    margin: 20px auto;
  }

  .checkmark-two {
    margin-top: -10px;
  }

  .continue-button-border {
    margin-top: -10px;
  }
}

@media only screen and (max-width: 575px) {
  .age-gate {
    aspect-ratio: 800 / 1347;
    height: 100%;
    max-height: 472px;
  }

  .age-gate-form {
    margin-top: 20px;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .form-box-containers {
    margin: 5px auto;
    padding: 0 0px;
  }

  .checkmark {
    height: 25px;
    width: 25px;
  }

  .checkmark-one {
    margin-top: -4px;
  }

  .checkmark-two {
    margin-top: -4px;
  }

  .checkmark-three {
    margin-top: 10px;
  }

  .custom-checkbox-container {
    padding-left: 30px;
  }

  .custom-checkbox-container .checkmark:after {
    left: 4.85px;
    top: 4.5px;
    width: 16px;
    height: 16px;
    border-width: 8px 1px 8px 1px;
  }

  .continue-button-border {
    margin-top: -10px;
    width: 200px;
  }

  .continue-btn {
    font-size: 0.6rem;
    padding: 20px 0;
  }
}

@media only screen and (max-width: 900px) {
  .age-gate .v-label {
    font-size: 0.8rem !important;
    line-height: 0.9rem !important;
    font-family: $primary-font-book;
    margin-top: 0;
    left: 0 !important;
  }

  .col {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .v-input--selection-controls {
    margin-top: 0 !important;
  }

  .form-box-containers {
    margin: 5px auto;
  }

  .checkmark {
    height: 25px;
    width: 25px;
  }

  .checkmark-one {
    margin-top: -5px;
  }

  .checkmark-two {
    margin-top: -5px;
  }

  .checkmark-three {
    margin-top: 10px;
  }

  .custom-checkbox-container {
    padding-left: 30px;
  }

  .custom-checkbox-container .checkmark:after {
    left: 4.85px;
    top: 4.5px;
    width: 16px;
    height: 16px;
    border-width: 8px 1px 8px 1px;
  }

  .continue-button-border {
    margin-top: -10px;
  }

  .continue-btn {
    font-size: 0.6rem;
    padding: 20px 0;
  }
}
</style>
