import Vue from "vue";
import VueRouter from "vue-router";
import AgeGate from "../views/AgeGate.vue";
import Menu from "../views/Menu.vue";
import "vuetify/dist/vuetify.min.css";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "AgeGate",
    component: AgeGate,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


export default router;
