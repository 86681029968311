<template>
  <v-footer fixed class="footer p-1">
    <div class="footer-container">
      <img
        src="../assets/images/18plus.png"
        alt="This product contains nicotine. Nicotine is an addictive substance"
        class="eighteen-over"
        width="200"
        height="auto"
      />
      <p class="text-white text-conditions text-upper m-0 ps-2">
        This website is for professional tobacco and nicotine retailers only.
      </p>
    </div>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@import "../assets/scss/main.scss";
.v-footer {
  border-radius: 0;
  height: 70px;
  background: linear-gradient(
    90deg,
    rgba(68, 39, 131, 1) 0%,
    rgba(159, 66, 143, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(68, 39, 131, 1) 0%,
    rgba(159, 66, 143, 1) 100%
  );
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%),
    0 0 0 0 rgb(0 0 0 / 12%);
  padding: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  align-items: center;
  display: flex;
  flex: 0 1 auto !important;
  flex-wrap: wrap;
  transition-duration: 0.2s;
  transition-property: background-color, left, right;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  .footer-container {
    flex: 0 0 100%;
    width: 96%;
    max-width: 96%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 2%;

    p {
      color: white;
      margin: 0;
      font-family: $primary-font-book;
      font-size: 0.5rem;
      font-weight: 600;
      line-height: 1;
    }
    @media all and (max-width: 900px) {
      .eighteen-over {
        width: 120px;
      }
    }
  }
}
</style>
