<template>
  <v-app id="app">
    <NewHeader id="dynamic-border" :style="headerStyle" class="pt-2" />
    <v-main id="dynamic-background" :style="backgroundStyle">
      <NewAcrossPageHexes v-if="$route.name !== 'AgeGate' && $route.name !==  'Menu' && $route.name !==  'News'" />
      <router-view />
    </v-main>
    <NewFooter id="dynamic-footer-border" :style="footerStyle" />
  </v-app>
</template>

<script>
import NewFooter from "./components/NewFooter.vue";
import NewAcrossPageHexes from "./components/NewAcrossPageHexes.vue";
import NewHeader from "./components/NewHeader.vue";

export default {
  components: {
    NewFooter,
    NewAcrossPageHexes,
    NewHeader,
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: 'var(--background-image-url)',
      };
    },

    headerStyle() {
      return {
        background: 'var(--background-style-color)',
      };
    },

    footerStyle() {
      return {
        background: 'var(--background-style-color)',
      };
    },
    
  },
};
</script>

<style lang="scss">
@import url('./assets/scss/main.scss');

.v-main {
  width: 100%;
  height: 100vh;
  //background-color: #722894;
  //background-image: url("./assets/images/background.png");
  background-repeat: no-repeat;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-size: cover;
  background-position: center;
}

.theme--light.v-application {
  background: none !important;
}

.grecaptcha-badge {
  display: none !important;
}
</style>
