import Vue from "vue";
import VueRouter from "vue-router";
import AgeGate from "../views/AgeGate.vue";
import Menu from "../views/Menu.vue";
import News from "../views/News.vue";
import "vuetify/dist/vuetify.min.css";

Vue.use(VueRouter);

const routes = [
  {
    path: "/age-gate",
    name: "AgeGate",
    component: AgeGate,
  },
  {
    path: "/",
    name: "Menu",
    component: Menu,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/how-to-use",
    name: "HowToUse",
    component: () => import("../views/HowToUse.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/product-range",
    name: "ProductRange",
    component: () => import("../views/ProductRange.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("../views/Faq.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/video",
    name: "EducationalVideos",
    component: () => import("../views/EducationalVideos.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/holding-page",
    name: "HoldingPage",
    component: () => import("../views/HoldingPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !localStorage.ageCheck) {
    next("/age-gate");
    return;
  }
  next();

  Vue.nextTick(() => {
    try {
      let backgroundImageUrl = require("../assets/images/background.png"); // Default background image
      let backgroundStyle =
        "linear-gradient(90deg, rgba(68, 39, 131, 1) 0%, rgba(159, 66, 143, 1) 100%)";
      // if (to.path === "/news") {
      //   backgroundImageUrl = require("../assets/images/background_orange.png");
      //   backgroundStyle =
      //     "linear-gradient(90deg, rgba(167,65,19,1) 0%, rgba(221,176,55,1) 50%, rgba(167,65,19,1) 100%)";
      // } else
      if (to.path === "/age-gate" || to.path === "/" || to.path === "/news") {
        backgroundImageUrl = require("../assets/images/background_yellow.png");
        backgroundStyle =
          "linear-gradient(90deg, rgb(255,122,0) 0%, rgb(255,148,0) 33%, rgb(255,118,105) 66%, rgb(255,149,152) 100%)";
      } else {
        backgroundStyle =
          "linear-gradient(90deg, rgba(68, 39, 131, 1) 0%, rgba(159, 66, 143, 1) 100%)";
        backgroundImageUrl == require("../assets/images/background.png");
      }
      const backgroundElement = document.getElementById("dynamic-background");
      const borderElement = document.getElementById("dynamic-border");
      const borderElementFooter = document.getElementById(
        "dynamic-footer-border"
      );

      if (backgroundElement) {
        backgroundElement.style.setProperty(
          "--background-image-url",
          `url(${backgroundImageUrl})`
        );
      }

      if (borderElement) {
        borderElement.style.setProperty(
          "--background-style-color",
          `${backgroundStyle}`
        );
      }

      if (borderElementFooter) {
        borderElementFooter.style.setProperty(
          "--background-style-color",
          `${backgroundStyle}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  });
});

export default router;
