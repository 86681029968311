<template>
  <div class="bg-circles"></div>
</template>

<script>
export default {
  components: {},

  data: () => ({}),
};
</script>

<style lang="scss">
$url-green-circle: url("../assets/images/bg-circle-4.png");
$url-orange-circle: url("../assets/images/bg-circle-5.png");
$url-light-blue-circle: url("../assets/images/bg-circle-6.png");
$url-purple-top-circle: url("../assets/images/bg-circle-3.png");
$url-dark-blue-top-circle: url("../assets/images/bg-circle-1.png");
$url-dark-blue-bottom-circle: url("../assets/images/bg-circle-2.png");

$page-background-image: $url-green-circle, $url-orange-circle,
  $url-light-blue-circle, $url-purple-top-circle, $url-dark-blue-top-circle,
  $url-dark-blue-bottom-circle;

$size-green-circle: max(19%, 180px);
$size-orange-circle: max(8%, 5rem);
$size-light-blue-circle: max(18%, 170px);
$size-purple-top-circle: max(10%, 100px);
$size-dark-blue-top-circle: max(4%, 35px);
$size-dark-blue-bottom-circle: max(12%, 110px);

$mobile-size-green-circle: max(12%, 130px);
$mobile-size-orange-circle: max(6%, 4rem);
$mobile-size-light-blue-circle: max(12%, 130px);
$mobile-size-purple-top-circle: max(7%, 80px);
$mobile-size-dark-blue-top-circle: max(4%, 35px);
$mobile-size-dark-blue-bottom-circle: max(9%, 100px);

$page-background-sizes: $size-green-circle, $size-orange-circle,
  $size-light-blue-circle, $size-purple-top-circle, $size-dark-blue-top-circle,
  $size-dark-blue-bottom-circle;

$mobile-page-background-sizes: $mobile-size-green-circle,
  $mobile-size-orange-circle, $mobile-size-light-blue-circle,
  $mobile-size-purple-top-circle, $mobile-size-dark-blue-top-circle,
  $mobile-size-dark-blue-bottom-circle;

$position-green-circle: -12% 10%;
$position-orange-circle: -3% 78%;
$position-light-blue-circle: 108% 66%;
$position-purple-top-circle: 98% 30px;
$position-dark-blue-top-circle: 23% 60px;
$position-dark-blue-bottom-circle: 50% 110%;

$page-background-position: $position-green-circle, $position-orange-circle,
  $position-light-blue-circle, $position-purple-top-circle,
  $position-dark-blue-top-circle, $position-dark-blue-bottom-circle;

$mobile-position-green-circle: -3.5rem 18%;
$mobile-position-orange-circle: -2rem 78%;
$mobile-position-light-blue-circle: 21rem 66%;
$mobile-position-purple-top-circle: 22rem 60px;
$mobile-position-dark-blue-top-circle: 2rem 60px;
$mobile-position-dark-blue-bottom-circle: 50% 100%;

$mobile-page-background-position: $mobile-position-green-circle,
  $mobile-position-orange-circle, $mobile-position-light-blue-circle,
  $mobile-position-purple-top-circle, $mobile-position-dark-blue-top-circle,
  $mobile-position-dark-blue-bottom-circle;

$small-mobile-page-background-position: $mobile-position-green-circle,
  $mobile-position-orange-circle, $mobile-position-light-blue-circle,
  $mobile-position-purple-top-circle, $mobile-position-dark-blue-top-circle,
  $mobile-position-dark-blue-bottom-circle;

$tablet-page-background-position: $position-green-circle,
  $position-orange-circle, $position-light-blue-circle,
  $position-purple-top-circle, $position-dark-blue-top-circle, 50% 100%;

.bg-circles {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: $page-background-image;
  background-size: $page-background-sizes;
  background-position: $page-background-position;
  background-repeat: no-repeat;
}

@media (max-width: 576px) {
  .bg-circles {
    background-position: $small-mobile-page-background-position;
    background-size: $mobile-page-background-sizes;
  }
}

// @media (min-height: 576px) and (max-width: 900px) {
//   .bg-circles {
//     background-position: $mobile-page-background-position;
//     background-size: $mobile-page-background-sizes;
//   }
// }

@media (min-width: 576px) {
  .bg-circles {
    // background-position: $page-background-position;
  }
}
</style>
