<template>
  <v-container>
    <v-row class="age-gate justify-content-center">
      <v-col class="age-gate-header p-0" cols="12">
        <h1>
          HELLO!<br>
WE HAVE DECIDED TO CLOSE<br>
THE LOGIC TRADE HUB
        </h1>
      </v-col>
      <v-col cols="12">
        <p class="px-6">WE HOPE THE INFORMATION HERE HAS BEEN HELPFUL.
FOR MORE DETAILS ABOUT LOGIC, PLEASE VISIT JTI COLLECT.
IF YOU HAVE NOT REGISTERED, PLEASE CONTACT
YOUR JTI REPRESENTATIVE.</p>
</v-col>
      <v-col cols="12">
        <div class="continue-button-border">
            <v-btn class="continue-btn" href="https://www.jti-collect.com/" target="_blank"
              >VISIT JTI COLLECT</v-btn
            >
          </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AgeGate",

  data: () => ({
    valid: true,
    checkbox1: false,
    checkbox2: false,
  }),

  methods: {
  },
};
</script>

<style lang="scss">
@import "../assets/scss/main.scss";

    h1 {
      line-height: 1.1;
    }
    .v-btn {
      background-color: transparent!important;
      background-image: unset!important;
      border: 1px solid #ffffff;
      color: white;
      padding: 15px 40px;
      border-radius: 40px!important;
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;
      letter-spacing: 2px;

      &::before {
        background-color: transparent!important;
      }

      @media (min-width: 576px) and (max-width: 900px) {
        padding: 1vw 2vw !important;
        font-size: max(1.6vh, 2.6vw);
      }
    }

  .age-gate {
    color: #ffffff;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    align-content: center;
    background: url("../assets/images/background_inner.png") no-repeat center
      center;
    background-size: contain;
    height: 65vh;
    text-align: center;
    margin: 0!important;
    .v-icon {
      font-size: max(1.2rem, 2.5vw) !important;
    }

    .v-label {
      left: 15px !important;
      font-size: 13px;
      font-weight: 600;
      font-family: $primary-font-bold;
    }

    .v-input--is-label-active label {
      color: #ffffff;
    }
  }

  .age-gate-header > h1 {
    text-align: center;
    color: white !important;
    -webkit-text-fill-color: white;
    font-size: max(1.3rem, 1.5vw);
    width: 90%;
    margin: 0 auto;
    font-weight: bold !important;
    font-family: $primary-font-bold;
  }

  .ts-cs-box {
    margin-top: -10px;
  }

  .error-message {
    height: 18px;
    color: rgb(179, 0, 0);
  }

  .fade-out {
    animation: fadeOut 1s forwards;
  }

  .all-boxes {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin: 2rem;
  }

  .form-box-containers {
    display: flex;
    width: 100%;
    // justify-content: center;
    align-items: center;
  }

  .continue-button-border {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    width: 200px;
  }

  .continue-btn {
    font-weight: bold;
    font-size: 1.4rem;
    border-radius: 30px;
    width: inherit;
    background-image: -webkit-linear-gradient(45deg, #4d2e8a, #0a003a);
    color: #ffffff !important;
    padding: 10px 10px;
    height: unset !important;
    font-family: $primary-font-bold;
    letter-spacing: normal !important;

    .v-btn__content {
      font-family: $primary-font-bold;
      font-weight: 600;
      padding: 10px;
    }

    &:disabled {
      cursor: not-allowed !important;
      background-image: -webkit-linear-gradient(
        45deg,
        #8080806a,
        #4a4467
      ) !important;
    }
  }
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(194, 194, 194) !important;
    cursor: not-allowed !important;
    background-image: -webkit-linear-gradient(
      45deg,
      #4a4467f5,
      #808080c3
    ) !important;
  }
  .custom-checkbox-container {
    position: relative;
    padding-left: 50px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    /* border-radius: 5px; */
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    height: 40px;
    width: 40px;
    background-color: #1b527a;
  }

  .checkmark-two {
    margin-top: -8px;
  }

  .custom-checkbox-container:hover input ~ .checkmark {
    background-color: #40aad7;
  }

  .custom-checkbox-container input:checked ~ :after {
    display: block;
  }

  .custom-checkbox-container input:checked ~ .checkmark {
    background-color: none;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .custom-checkbox-container .checkmark:after {
    left: 10px;
    top: 11px;
    width: 18px;
    height: 18px;
    border: solid #ffffff;
    border-width: 10px 10px 9px 10px;
    /* transform: rotate(45deg);*/
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  }

  .portrait-only {
    display: none;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  /* MEDIA QUERIES */
  @media (max-width: 992px) {
    .age-gate {
      position: absolute;
      background-image: url("../assets/images/background_inner_mobile_straight.png");
      background-size: cover;
    }
    .age-gate-form {
      margin-top: 20px;
      font-size: 0.8rem;
    }

  }

  @media only screen and (max-width: 900px) {
    .age-gate-header > h1 {
      font-size: 1.8vh;
      width: 100%;
      margin: 0 auto 0;
    }

    .all-boxes {
      flex-direction: column;
      gap: 0;
      margin: 0 15%;
    }

    .age-gate-form {
      margin-top: 10px;
      font-size: 1rem;
    }

    .form-box-containers {
      margin: 20px auto 20px 10px;
    }

    .continue-button-border {
      width: 80% !important;
      margin: 10% !important;
      display: flex;
      justify-content: center;
    }

    .continue-btn {
      font-size: 0.9rem;
    }
  }

  @media only screen and (max-width: 670px) {
    .age-gate-form {
      margin-top: 20px;
      font-size: 0.8rem;
    }

    .form-box-containers {
      margin: 20px auto;
    }

    .checkmark-two {
      margin-top: -10px;
    }

    .continue-button-border {
      margin-top: -10px;
    }
  }

  @media only screen and (max-width: 575px) {
    .age-gate {
      width: 90%;
      margin: auto;
    }

    .age-gate-form {
      margin-top: 20px;
      font-size: 0.8rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .form-box-containers {
      margin: 5px auto;
      padding: 0 0px;
    }

    .checkmark {
      height: 25px;
      width: 25px;
    }

    .checkmark-one {
      margin-top: -4px;
    }

    .checkmark-two {
      margin-top: -4px;
    }

    .checkmark-three {
      margin-top: 10px;
    }

    .custom-checkbox-container {
      padding-left: 30px;
    }

    .custom-checkbox-container .checkmark:after {
      left: 4.85px;
      top: 4.5px;
      width: 16px;
      height: 16px;
      border-width: 8px 1px 8px 1px;
    }

    .continue-button-border {
      margin-top: -10px;
      width: 200px;
    }

    .continue-btn {
      font-size: 0.6rem;
      padding: 20px 0;
    }
  }

  @media only screen and (max-width: 414px) {
    .age-gate .v-label {
      font-size: 0.8rem !important;
      line-height: 0.9rem !important;
      font-family: $primary-font-book;
      margin-top: 0;
      left: 0 !important;
    }

    .col {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
    .v-input--selection-controls {
      margin-top: 0 !important;
    }

    .form-box-containers {
      margin: 5px auto;
    }

    .checkmark {
      height: 25px;
      width: 25px;
    }

    .checkmark-one {
      margin-top: -5px;
    }

    .checkmark-two {
      margin-top: -5px;
    }

    .checkmark-three {
      margin-top: 10px;
    }

    .custom-checkbox-container {
      padding-left: 30px;
    }

    .custom-checkbox-container .checkmark:after {
      left: 4.85px;
      top: 4.5px;
      width: 16px;
      height: 16px;
      border-width: 8px 1px 8px 1px;
    }

    .continue-button-border {
      margin-top: -10px;
    }

    .continue-btn {
      font-size: 0.6rem;
      padding: 20px 0;
    }
  }

  @media (max-width: 425px) {
      h1 {
        font-size: 1.2em!important;
        padding: 0;
      }
      p {
        font-size: 0.9em;
        padding-top: 20px;
      }
    }
</style>
