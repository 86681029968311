<template>
  <div>
    <div class="menu-wrapper">
      <v-row
        class="d-flex justify-center pa-0 align-content-center menu-container"
      >
        <v-col cols="12" sm="12" class="mx-0 d-flex pa-0 menu-inner-container">
          <v-row class="menu-item-container">
            <v-col cols="12" class="menu-header pa-0 pa-sm-0">
              <h1 v-if="$vuetify.breakpoint.xs" class="menu-title p-0 mt-0">
                ONE DEVICE
                <br />
                MULTIPLE
                <br />
                FLAVOURS
              </h1>
              <h1 v-else="$vuetify.breakpoint.sm" class="menu-title p-0 mt-4">
                ONE DEVICE
                <br />
                MULTIPLE FLAVOURS
              </h1>

              <h3
                v-if="$vuetify.breakpoint.xs"
                class="menu-title-description mt-2 mt-sm-0 pb-1 pb-2"
              >
                <span class="highlight">POP</span>
                YOUR POD AND
                <br />
                CHANGE YOUR FLAVOUR
              </h3>
              <h3
                v-else="$vuetify.breakpoint.sm"
                class="menu-title-description pb-1 pb-2"
              >
                <span class="highlight">POP</span>
                YOUR POD AND CHANGE YOUR FLAVOUR
              </h3>
            </v-col>
            <v-col cols="12 py-0 px-0 px-sm-8 px-md-10 px-xl-6">
              <v-row class="px-10 px-sm-0">
                <v-col cols="6" sm="4" class="menu-item px-0 py-1 py-md-2">
                  <router-link to="/news">
                    <v-img
                      class="menu-button mx-auto"
                      src="../assets/images/icon-promotions.png"
                      alt="Promotions"
                      position="center center"
                      contain
                    />
                    <h6 class="mt-1 mt-sm-2 pb-2 pt-0 pt-md-2">LOGIC NEWS</h6>
                  </router-link>
                </v-col>
                <v-col cols="6" sm="4" class="menu-item px-0 py-1 py-md-2">
                  <router-link to="/about">
                    <v-img
                      class="menu-button mx-auto"
                      src="../assets/images/icon-about.png"
                      alt="About Logic"
                      position="center center"
                      contain
                    />
                    <h6 class="mt-1 mt-sm-2 pb-2 pt-0 pt-md-2">
                      ABOUT LOGIC VAPE
                    </h6>
                  </router-link>
                </v-col>
                <v-col cols="6" sm="4" class="menu-item px-0 py-1 py-md-2">
                  <router-link to="/how-to-use">
                    <v-img
                      class="menu-button mx-auto"
                      src="../assets/images/icon-how_to_use.png"
                      alt="What Is Vaping"
                      position="center center"
                      contain
                    />
                    <h6 class="mt-1 mt-sm-2 pb-2 pt-0 pt-md-2">HOW TO USE</h6>
                  </router-link>
                </v-col>
                <v-col cols="6" sm="4" class="menu-item px-0 py-1 py-md-2">
                  <router-link to="/video">
                    <v-img
                      class="menu-button mx-auto"
                      src="../assets/images/icon-video.png"
                      alt="News"
                      position="center center"
                      contain
                    />
                    <h6
                      v-if="$vuetify.breakpoint.xl"
                      class="mt-1 mt-sm-2 pb-2 pt-0 pt-md-2"
                    >
                      EDUCATIONAL VIDEOS
                    </h6>
                    <h6
                      v-else="$vuetify.breakpoint.xs"
                      class="mt-1 mt-sm-2 pb-2 pt-0 pt-md-2"
                    >
                      EDUCATIONAL
                      <br />
                      VIDEOS
                    </h6>
                  </router-link>
                </v-col>
                <v-col cols="6" sm="4" class="menu-item px-0 py-1 py-md-2">
                  <router-link to="/product-range">
                    <v-img
                      class="menu-button mx-auto"
                      src="../assets/images/icon-product_range.png"
                      alt="Product Range"
                      position="center center"
                      contain
                    />
                    <h6 class="mt-1 mt-sm-2 pb-2 pt-1 pt-md-2">
                      PRODUCT RANGE
                    </h6>
                  </router-link>
                </v-col>
                <v-col cols="6" sm="4" class="menu-item px-0 py-1 py-md-2">
                  <router-link to="/faq">
                    <v-img
                      class="menu-button mx-auto"
                      src="../assets/images/icon-faq.png"
                      alt="Faqs"
                      position="center center"
                      contain
                    />
                    <h6 class="mt-1 mt-sm-2 pb-2 pt-1 pt-md-2">FAQ</h6>
                  </router-link>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
};
</script>

<style lang="scss">
@import "../assets/scss/main.scss";

.menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100vh;
  z-index: 10;
  position: relative;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
  }
}

.menu-container {
  width: 100%;
  aspect-ratio: 677 / 585;
  background-image: url("../assets/images/background_inner_yellow_pack.png");
  background-size: contain;
  background-position: center center;

  @media only screen and (max-width: 600px) {
    max-width: 352px;
    aspect-ratio: 800 / 1347;
    max-height: 550px;

    .menu-inner-container {
      width: 100%;
      aspect-ratio: 800 / 1347;
      align-items: center;
      justify-content: center;
    }

    .menu-item-container {
      right: inherit;
      top: inherit;
      scale: 1;
      width: 100%;
      aspect-ratio: 800 / 1347;
      height: unset;
      max-height: 532px;

      .v-image {
        height: 78px;
      }
    }

    .menu-title {
      font-size: 22px;
    }

    .menu-title-description {
      font-size: 14px;
      line-height: 1;
    }

    .menu-sub {
      .v-image {
        width: 120px !important;
        height: 5.2vh;
      }
    }
  }

  @media only screen and (min-width: 599px) and (max-width: 960px) {
    /* Styles for screens between 600px and 960px */
    // max-width: 60vw;
    // min-width: 480px;

    width: 650px;

    .menu-sub {
      .v-image {
        width: 180px;
      }
    }

    h1 {
      font-size: 32px !important;
    }

    h6 {
      font-size: 12px !important;
    }
  }

  @media only screen and (min-width: 960px) and (max-width: 1264px) {
    h1 {
      font-size: 32px !important;
    }

    max-width: 70vw;
    min-width: 575px;

    .menu-sub {
      .v-image {
        width: 180px;
      }
    }
  }

  @media only screen and (min-width: 1264px) and (max-width: 1903px) {
    width: 900px;
    height: 582px;

    h1 {
      font-size: 38px !important;
    }

    h6 {
      font-size: 14px;
    }

    .menu-sub {
      .v-image {
        min-width: 200px;
        max-width: 20vw;
      }
    }

    .menu-item .v-image {
      width: 125px;
    }

    .menu-item-container {
      width: 580px;
      height: 558px;
    }
  }

  @media only screen and (min-width: 1903px) {
    width: 900px;
    height: 582px;

    h1 {
      font-size: 38px !important;
    }

    h6 {
      font-size: 14px;
    }

    .menu-item-container {
      scale: 0.7;
      width: 580px;
      height: 558px;
    }
  }
}

.menu-item-container {
  width: 575px;
  scale: 0.65;
  right: -20%;
  top: 3%;
  height: unset;
  margin: 0 !important;
  z-index: 100 !important;
  position: relative;
  padding: 0;
  justify-content: center;
  aspect-ratio: 677 / 585;
}

.menu-title {
  font-size: min(3.75vw, 4.8vh);
  line-height: 1em;
  font-weight: normal;
  font-family: $primary-font-black, "Montserrat";
  color: rgb(10, 2, 2) !important;
  -webkit-text-fill-color: white !important;
  font-weight: 900;
}

.menu-title-description {
  font-size: 12px;
  font-weight: normal;
  font-family: $primary-font-black;
  color: white !important;
}

.menu-sub {
  .v-image {
    width: 200px;
  }
}

.logic-bold {
  font-family: $primary-font-black;
}

.highlight {
  color: #893ede;
  font-style: italic;
}

.menu-header {
  text-align: center;
  font-family: $primary-font-book;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    margin: 0 !important;
  }

  p {
    font-size: max(0.9vw, 1.1vh);
    line-height: 22px;
    color: white;

    @media only screen and (max-width: 768px) {
      font-size: 11px;
      line-height: 1;
    }
  }
}

.menu-button-row {
  padding: 20px;
  width: 100%;
}

.menu-button {
  width: 80%;
}

.social-container-row {
  display: flex;
  width: 100%;
  justify-content: center;
}

.social-image {
  margin: 10px 50px 0 10px;
  width: 35px;
}

.fade-in {
  animation: fadeIn 1s forwards;
}

.inactive {
  display: none;
}

.menu-item {
  text-align: center;
  font-family: $primary-font-book !important;

  h6 {
    font-size: 14px;
    color: #fff;

    @media only screen and (max-width: 768px) {
      font-size: 10px;
    }

    line-height: 16px !important;
  }

  a {
    text-decoration: none;
  }

  .v-image {
    width: 120px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media only screen and (max-height: 790px) {
  .social-image {
    width: 25px;
  }
}

// @media (min-width: 576px) and (max-width: 992px) {
//   .menu-item-container {
//     width: 900px;
//   }
// }

@media (max-width: 600px) {
  .main-menu-launch-video-container > .modal-container > .close-modal {
    width: 8%;
  }

  .menu-container {
    margin: 0 !important;
    padding: 8vw;
    background-image: url("../assets/images/background_inner_mobile_yellow.png");
    background-size: contain;
    background-position: center center;
    position: relative;

    p {
      font-size: 10px;
      line-height: 10px;
    }
  }

  @media only screen and (max-height: 450px) {
    .modal-bg {
      height: 80vh;
    }

    .menu-button-row {
      width: 50%;
    }
  }
}

// @media (max-width: 992px) and (max-height: 500px) {
//   .menu-item-container {
//     margin: 0 !important;
//     padding: 0vw;
//     background-image: url("../assets/images/background_inner.png") !important;
//     background-size: contain;
//     background-position: center center;
//     position: relative;
//     p {
//       font-size: 10px;
//       line-height: 10px;
//     }
//   }
// }

.v-dialog--persistent {
  box-shadow: unset !important;
}

.video-container {
  background: none !important;
  height: 40vh !important;
  min-height: 290px;
  border-radius: 0 !important;
  border: none !important;
  margin-top: 50px;
  z-index: 2;
  flex-direction: column;

  video {
    border: 2px solid #fff;
  }
}

.intro-text {
  text-transform: uppercase;
  font-size: 1.2em;
}

.close-video-button {
  z-index: 10;
  position: absolute;

  img {
    width: 80%;
  }
}

.v-card {
  color: #1e4b8b !important;

  &__title {
    text-transform: uppercase;
    line-height: 1.2em !important;
  }

  &__text {
    color: #1e4b8b !important;
    line-height: 1.2em !important;
  }
}

// @media only screen and (max-width: 670px) {
//   .intro-text {
//     text-transform: uppercase;
//     font-size: 0.8em;
//   }

//   .video-container {
//     height: 200px !important;
//   }

//   .close-video-button img {
//     width: 50%;
//   }

//   .menu-title {
//     font-size: 1rem;
//   }

//   .menu-sub {
//     font-size: 0.7rem !important;
//     line-height: 1rem;
//     margin: 0 0 20px;
//   }
// }

.dialog-btn-container {
  flex-direction: column;
}

.angled-btn {
  cursor: pointer;
  clip-path: polygon(91% 0, 100% 50%, 91% 100%, 9% 100%, 0 50%, 9% 0);
  margin: 0 auto;
  display: block;
  font-weight: bold;
  font-size: 14px;
  background-color: rgba(30, 75, 139, 1);
  border: none;
  transition: all 0.2s ease-in-out;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  padding: 15px 50px;
  font-weight: 600;
}
</style>
